@mixin transition($style, $duration){
  -webkit-transition: $style $duration;
  -moz-transition: $style $duration;
  -ms-transition: $style $duration;
  -o-transition: $style $duration;
  transition: $style $duration;
}

@mixin bg-linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}