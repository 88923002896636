.folder-image {
  background: #8a9e92;
  border-radius: 100%;
  margin: 10px auto 10px auto;
  width: 65%;
  color: #fff;
  font-size: 20px; }
  .folder-image:hover {
    background-color: #a6b5ac; }
  .folder-image.selected {
    background-color: #6f8678; }
  .folder-image.special {
    background-color: #0c2340; }
    .folder-image.special:hover {
      background-color: #143a6b; }
  .folder-image i.fa {
    font-size: 46px;
    margin-top: 16px; }
  .folder-image img {
    margin: 5px; }

.client-view-folders .folder-image {
  width: 80px;
  height: 80px; }

select {
  border: 1px solid #8a9e92; }

html {
  min-height: 100vh;
  border-top: 3px solid #8a9e92; }

body {
  font-family: arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #333;
  background: url(http://www.influence.co.uk/wp-content/uploads/2014/06/rhf-01-1248x702.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-transition: background 1s;
  -moz-transition: background 1s;
  -ms-transition: background 1s;
  -o-transition: background 1s;
  transition: background 1s; }

body:before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.row.with-margins {
  margin: auto 10px; }

.columns, .column {
  display: inline-block; }

/* ---------------------- Misc -----------------------*/
.hidden {
  display: none; }

#response {
  /*padding: 10px 10px 10px 0px;*/ }

.spacing {
  height: 40px; }

.fright {
  position: relative;
  float: right; }

.fleft {
  position: relative;
  float: left; }

.tright {
  text-align: right; }

h1 {
  font-size: 24px;
  font-weight: 600; }

#main-wrapper {
  min-height: 100vh;
  z-index: 2;
  position: relative; }

#left-nav, header {
  background-color: #dcdcdc; }

.top-message {
  background: #8a9e92;
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  padding: 5px;
  font-size: 14px;
  top: 0;
  left: 0; }

header {
  height: 3.5rem;
  padding: 15px 50px;
  font-size: 14px;
  font-weight: 400; }

header .content {
  margin-top: 3px; }

header .return {
  margin-left: -30px; }

header .logout {
  margin-left: 30px;
  text-align: right; }

header .spacer {
  padding: 0 7px 0 7px; }

header a {
  color: #333; }

main {
  padding: 40px 50px 40px 50px; }

#logo {
  height: 12rem; }

#logo img {
  display: block;
  margin: auto;
  padding-top: 25px; }

/** Extra styles **/
.no-padding {
  padding: 0;
  margin: 0; }

#pjax-container {
  position: relative; }

.login-container {
  width: 400px;
  margin: auto;
  background-color: #fff;
  padding: 20px 70px 20px 70px;
  min-height: 99.7vh;
  z-index: 9999;
  position: relative; }

.login-container button {
  width: 100%;
  background-color: #8a9e92;
  text-align: center;
  color: #fff;
  padding: 15px;
  border-bottom: 5px solid #587c92;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px; }

.login-container a {
  color: #333;
  text-decoration: underline; }

.datepicker.dropdown-menu td.day:hover,
.datepicker.dropdown-menu span.hour:hover,
.datepicker.dropdown-menu span.minute:hover {
  background-color: #8a9e92;
  color: #fff; }

.datepicker.dropdown-menu .active,
.datepicker.dropdown-menu td.day.active,
.datepicker.dropdown-menu span.minute.active,
.datepicker.dropdown-menu span.hour.active {
  background-color: #8a9e92;
  color: #fff; }

/* ------------ Autocomplete -----------*/
.autocomplete-search-results {
  max-height: 200px;
  overflow: hidden;
  border: 1px solid #ccc; }

.autocomplete-search-results.hidden {
  display: none; }

.autocomplete-search-results .result {
  padding: 10px;
  margin-left: -40px; }

.autocomplete-search-results .result:hover {
  background-color: #211b56;
  color: #fff; }

/* ---------------------- Sites ---------------------- */
#right-content {
  background-color: #fff; }

.list-sites {
  display: flex;
  justify-content: space-between; }

.site {
  text-align: center;
  width: 120px;
  float: left;
  position: relative; }

.site:hover {
  cursor: pointer; }

.site p {
  font-size: 14px; }

.site-content {
  position: relative;
  margin-top: 10px; }

.site-btn {
  height: 100px;
  width: 100px;
  background-color: #8a9e92;
  border-radius: 100%;
  margin: 15px auto;
  padding-top: 3px; }

.site-btn.special {
  background-color: #0c2340; }

.site-btn .layer {
  height: 100%;
  width: 100%;
  border-radius: 100%; }

.site-btn:hover .layer {
  opacity: 0.3;
  z-index: 100; }

.site-btn:hover .layer .featured-img {
  z-index: 1;
  position: relative; }

.site-btn .featured-img {
  border-radius: 100%;
  width: 94px;
  height: 94px;
  background-size: cover;
  background-position: center;
  margin: auto; }

.single-doc {
  font-size: 18px;
  width: 100%;
  margin: auto;
  border-top: 1px solid #8a9e92;
  padding-bottom: 10px; }

.single-doc .row {
  padding-left: 15px;
  padding-right: 30px; }

.single-doc .icon-area {
  padding: 12px 0px 0px 10px; }

.single-doc .icon-area .fa {
  font-size: 46px;
  color: #8a9e92; }

.single-doc .section {
  padding: 20px 0px 10px 0px;
  position: relative; }

.sub-categories {
  margin-left: 20px; }

.item-name {
  position: relative;
  float: left;
  width: 90%;
  font-size: 14px;
  color: #1a1a1a;
  font-weight: 600; }

.item-name p {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 0px; }

.item-name p.updated {
  color: #555;
  font-weight: 500; }

.mce-btn button {
  width: 30px !important;
  padding: 0px 2px !important;
  background-color: transparent !important; }

.save-content {
  background-color: #8a9e92;
  font-size: 16px;
  color: #fff;
  margin: auto;
  width: 150px;
  display: block;
  padding: 10px !important;
  margin-top: 15px; }

.item-name input {
  border-top: 0px solid #fff;
  border-right: 0px solid #fff;
  border-bottom: 1px solid #d4d0d0;
  border-left: 0px solid #fff;
  margin-bottom: 0px;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0);
  padding: 6px 0px 5px 0px;
  height: auto;
  outline: none; }

.item-name input:focus {
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0);
  border-top: 0px solid #fff;
  border-right: 0px solid #fff;
  border-bottom: 1px solid #d4d0d0;
  border-left: 0px solid #fff; }

.item-name:hover {
  cursor: pointer; }

.item-options {
  width: 10%;
  position: relative;
  float: right;
  padding-top: 19px; }

.item-options .controller {
  width: 25px;
  height: 25px;
  background-color: #8a9e92;
  color: #fff;
  float: right;
  font-size: 14px;
  text-align: center; }

.item-options .controller:hover {
  cursor: pointer; }

.item-attr {
  font-size: 12px;
  color: #0074a2; }

.item-attr:hover {
  cursor: pointer; }

.controller {
  width: 25px;
  height: 25px;
  background-color: #8a9e92;
  color: #fff;
  float: right;
  font-size: 14px;
  text-align: center; }

.controller:hover {
  cursor: pointer; }

.dropzone {
  min-height: 500px;
  height: 100%;
  border: 2px dashed #8a9e92;
  text-align: center;
  font-size: 38px;
  color: #999999;
  padding-top: 100px;
  position: absolute;
  top: 0px;
  left: -25px;
  right: -25px;
  display: none; }

.dropzone span {
  display: none; }

.confirm-category {
  width: 100%;
  text-align: right;
  padding-right: 0px;
  padding-top: 2px;
  font-size: 25px; }

.btn {
  padding: 9px 14px 9px 14px;
  color: #fff;
  font-weight: 300;
  margin: 0px 5px 10px 0px; }

.grey-btn {
  background-color: #e9e9e9;
  border: 2px solid #e9e9e9;
  color: #000; }

.pink-btn {
  background-color: #8a9e92;
  border: 1px solid #8a9e92;
  padding: 9px 14px 9px 14px;
  color: #fff;
  font-weight: 300;
  margin: 0px 5px 10px 0px; }
  .pink-btn.full-width {
    width: 100%; }
  .pink-btn:hover {
    background: #a6b5ac;
    color: #fff; }

.pink-btn .icon {
  padding-right: 7px; }

.add-folder-btn {
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
  padding: 9px 14px 9px 14px;
  color: #333;
  font-weight: 300;
  margin: 10px 0px 10px 0px; }

.add-folder-btn .icon {
  padding-right: 7px; }

.pink-btn:hover,
.add-folder-btn:hover {
  cursor: pointer; }

.instructions {
  margin-bottom: 20px;
  color: #444; }

.instructions p {
  color: #444;
  font-size: 14px;
  margin: 0px; }

.folder-head {
  margin-bottom: 20px; }

.folder-head-icon {
  margin-bottom: 20px; }

.featured-banner {
  background-size: cover;
  height: 250px;
  width: 100%;
  background-position: center; }

input {
  border: 1px solid #8a9e92 !important; }

[type=file] {
  min-height: 80%; }

.featured-image-inputs {
  height: 150px; }

form .row {
  margin-top: 5px;
  margin-bottom: 0; }

form .row label {
  color: #333;
  font-size: 14px;
  padding-bottom: 5px; }

/*.nested li .active:hover{*/
/*color:#333 !important;*/
/*background-color: #d0d0d0 !important;*/
/*}*/
.large-btn {
  font-size: 18px;
  font-weight: 400;
  padding: 10px 30px 10px 30px;
  width: 200px;
  text-align: center;
  margin: auto;
  display: block;
  top: 15px;
  position: relative; }

.file-border {
  border: 1px solid #8a9e92;
  min-height: 85%;
  padding-top: 40px;
  position: relative;
  background: no-repeat center;
  background-clip: padding-box; }

.file-border p {
  font-size: 14px;
  color: #626262;
  text-align: center;
  margin-bottom: 0px; }

.file-uploads {
  position: absolute;
  top: 0px;
  overflow: hidden;
  height: 126px; }

.file-uploads input[type="file"] {
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0); }

.upload-btn, #add-folder-btn {
  margin-top: 18px; }

.prev-img {
  display: none;
  position: absolute;
  max-height: 80%;
  max-width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.user-form input[type="text"],
.user-form input[type="password"],
.user-form select {
  width: 92%; }

.category-name {
  text-transform: capitalize; }

p.category-updated {
  font-weight: normal;
  color: #545454; }

.cur:hover {
  cursor: pointer; }

.interactive:hover {
  cursor: pointer;
  background-color: #a6b5ac; }

.nav-bk {
  padding-right: 10px; }

.client-view {
  margin-top: 1.5rem; }

.client-view .site-name {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.client-view .client-logo {
  display: block;
  margin: auto; }

.dash-content {
  margin-bottom: 50px; }

.user-list {
  margin-bottom: 50px; }

#searchBtn {
  display: none; }

.save-site {
  margin-top: 20px; }

.ns-box.ns-show, .ns-box.ns-visible {
  z-index: 1000; }

#response {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #fff;
  border: 1px solid #8a9e92;
  z-index: 10001;
  box-shadow: 0px 0px 8px #8a9e92; }

#response div {
  padding: 5px; }

.interactive:hover {
  background-color: #587c92; }

input[disabled], input[disabled]:hover {
  background-color: #ccc;
  color: #676767;
  border: 1px solid #ccc;
  cursor: default; }

.colorpicker {
  width: 356px;
  height: 176px;
  overflow: hidden;
  position: absolute;
  background: url(images/colorpicker/colorpicker_background.png);
  font-family: Arial, Helvetica, sans-serif;
  display: none; }

.colorpicker_color {
  width: 150px;
  height: 150px;
  left: 14px;
  top: 13px;
  position: absolute;
  background: #f00;
  overflow: hidden;
  cursor: crosshair; }

.colorpicker_color div {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
  background: url(images/colorpicker/colorpicker_overlay.png); }

.colorpicker_color div div {
  position: absolute;
  top: 0;
  left: 0;
  width: 11px;
  height: 11px;
  overflow: hidden;
  background: url(images/colorpicker/colorpicker_select.gif);
  margin: -5px 0 0 -5px; }

.colorpicker_hue {
  position: absolute;
  top: 13px;
  left: 171px;
  width: 35px;
  height: 150px;
  cursor: n-resize; }

.colorpicker_hue div {
  position: absolute;
  width: 35px;
  height: 9px;
  overflow: hidden;
  background: url(images/colorpicker/colorpicker_indic.gif) left top;
  margin: -4px 0 0 0;
  left: 0px; }

.colorpicker_new_color {
  position: absolute;
  width: 60px;
  height: 30px;
  left: 213px;
  top: 13px;
  background: #f00; }

.colorpicker_current_color {
  position: absolute;
  width: 60px;
  height: 30px;
  left: 283px;
  top: 13px;
  background: #f00; }

.colorpicker input {
  background-color: transparent;
  border: 1px solid transparent;
  position: absolute;
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
  color: #898989;
  top: 4px;
  right: 11px;
  text-align: right;
  margin: 0;
  padding: 0;
  height: 11px; }

.colorpicker_hex {
  position: absolute;
  width: 72px;
  height: 22px;
  background: url(images/colorpicker/colorpicker_hex.png) top;
  left: 212px;
  top: 142px; }

.colorpicker_hex input {
  right: 6px; }

.colorpicker_field {
  height: 22px;
  width: 62px;
  background-position: top;
  position: absolute; }

.colorpicker_field span {
  position: absolute;
  width: 12px;
  height: 22px;
  overflow: hidden;
  top: 0;
  right: 0;
  cursor: n-resize; }

.colorpicker_rgb_r {
  background-image: url(images/colorpicker/colorpicker_rgb_r.png);
  top: 52px;
  left: 212px; }

.colorpicker_rgb_g {
  background-image: url(images/colorpicker/colorpicker_rgb_g.png);
  top: 82px;
  left: 212px; }

.colorpicker_rgb_b {
  background-image: url(images/colorpicker/colorpicker_rgb_b.png);
  top: 112px;
  left: 212px; }

.colorpicker_hsb_h {
  background-image: url(images/colorpicker/colorpicker_hsb_h.png);
  top: 52px;
  left: 282px; }

.colorpicker_hsb_s {
  background-image: url(images/colorpicker/colorpicker_hsb_s.png);
  top: 82px;
  left: 282px; }

.colorpicker_hsb_b {
  background-image: url(images/colorpicker/colorpicker_hsb_b.png);
  top: 112px;
  left: 282px; }

.colorpicker_submit {
  position: absolute;
  width: 22px;
  height: 22px;
  background: url(images/colorpicker/colorpicker_submit.png) top;
  left: 322px;
  top: 142px;
  overflow: hidden; }

.colorpicker_focus {
  background-position: center; }

.colorpicker_hex.colorpicker_focus {
  background-position: bottom; }

.colorpicker_submit.colorpicker_focus {
  background-position: bottom; }

.colorpicker_slider {
  background-position: bottom; }

.mce-tinymce {
  border: 1px solid #8a9e92 !important; }

.mce-container.mce-panel {
  margin-right: 2px; }

/* The Modal (background) */
#modal-popup, #modal-popup-alert {
  position: fixed;
  /* Stay in place */
  z-index: 9999;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 9999;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  transition: display 1s; }

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 400px;
  /* Could be more or less, depending on screen size */
  overflow: auto;
  border-radius: 0px;
  position: relative; }

.modal-content p {
  font-size: 14px;
  color: #333; }

.modal-content button {
  padding: 5px 15px 5px 15px;
  background-color: #8a9e92;
  color: #fff;
  font-size: 14px;
  border: 0px;
  outline: 0px; }

/* The Close Button */
.closebtn {
  color: #aaa;
  right: 6px;
  top: -7px;
  font-size: 28px;
  font-weight: bold;
  position: absolute; }

.closebtn:hover,
.closebtn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer; }

.ns-effect-flip.ns-type-notice {
  background-color: rgba(138, 158, 146, 0.73); }

.ns-type-error {
  background-color: rgba(68, 16, 41, 0.73); }

#loading-overlay {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 2;
  display: none; }
  #loading-overlay .animation-wrapper {
    display: table-cell;
    vertical-align: middle; }
    #loading-overlay .animation-wrapper .animation {
      position: relative;
      width: 78px;
      height: 78px;
      margin: auto; }
      #loading-overlay .animation-wrapper .animation .wBall {
        position: absolute;
        width: 74px;
        height: 74px;
        opacity: 0;
        transform: rotate(225deg);
        -o-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
        -moz-transform: rotate(225deg);
        animation: loading-orbit 6.96s infinite;
        -o-animation: loading-orbit 6.96s infinite;
        -ms-animation: loading-orbit 6.96s infinite;
        -webkit-animation: loading-orbit 6.96s infinite;
        -moz-animation: loading-orbit 6.96s infinite; }
        #loading-overlay .animation-wrapper .animation .wBall .wInnerBall {
          position: absolute;
          width: 10px;
          height: 10px;
          background: #8a9e92;
          left: 0;
          top: 0;
          border-radius: 10px; }
        #loading-overlay .animation-wrapper .animation .wBall#wBall_1 {
          animation-delay: 1.52s;
          -o-animation-delay: 1.52s;
          -ms-animation-delay: 1.52s;
          -webkit-animation-delay: 1.52s;
          -moz-animation-delay: 1.52s; }
        #loading-overlay .animation-wrapper .animation .wBall#wBall_2 {
          animation-delay: 0.3s;
          -o-animation-delay: 0.3s;
          -ms-animation-delay: 0.3s;
          -webkit-animation-delay: 0.3s;
          -moz-animation-delay: 0.3s; }
        #loading-overlay .animation-wrapper .animation .wBall#wBall_3 {
          animation-delay: 0.61s;
          -o-animation-delay: 0.61s;
          -ms-animation-delay: 0.61s;
          -webkit-animation-delay: 0.61s;
          -moz-animation-delay: 0.61s; }
        #loading-overlay .animation-wrapper .animation .wBall#wBall_4 {
          animation-delay: 0.91s;
          -o-animation-delay: 0.91s;
          -ms-animation-delay: 0.91s;
          -webkit-animation-delay: 0.91s;
          -moz-animation-delay: 0.91s; }
        #loading-overlay .animation-wrapper .animation .wBall#wBall_5 {
          animation-delay: 1.22s;
          -o-animation-delay: 1.22s;
          -ms-animation-delay: 1.22s;
          -webkit-animation-delay: 1.22s;
          -moz-animation-delay: 1.22s; }

@keyframes loading-orbit {
  0% {
    opacity: 1;
    z-index: 99;
    transform: rotate(180deg);
    animation-timing-function: ease-out; }
  7% {
    opacity: 1;
    transform: rotate(300deg);
    animation-timing-function: linear;
    origin: 0%; }
  30% {
    opacity: 1;
    transform: rotate(410deg);
    animation-timing-function: ease-in-out;
    origin: 7%; }
  39% {
    opacity: 1;
    transform: rotate(645deg);
    animation-timing-function: linear;
    origin: 30%; }
  70% {
    opacity: 1;
    transform: rotate(770deg);
    animation-timing-function: ease-out;
    origin: 39%; }
  75% {
    opacity: 1;
    transform: rotate(900deg);
    animation-timing-function: ease-out;
    origin: 70%; }
  76% {
    opacity: 0;
    transform: rotate(900deg); }
  100% {
    opacity: 0;
    transform: rotate(900deg); } }

@-o-keyframes loading-orbit {
  0% {
    opacity: 1;
    z-index: 99;
    -o-transform: rotate(180deg);
    -o-animation-timing-function: ease-out; }
  7% {
    opacity: 1;
    -o-transform: rotate(300deg);
    -o-animation-timing-function: linear;
    -o-origin: 0%; }
  30% {
    opacity: 1;
    -o-transform: rotate(410deg);
    -o-animation-timing-function: ease-in-out;
    -o-origin: 7%; }
  39% {
    opacity: 1;
    -o-transform: rotate(645deg);
    -o-animation-timing-function: linear;
    -o-origin: 30%; }
  70% {
    opacity: 1;
    -o-transform: rotate(770deg);
    -o-animation-timing-function: ease-out;
    -o-origin: 39%; }
  75% {
    opacity: 1;
    -o-transform: rotate(900deg);
    -o-animation-timing-function: ease-out;
    -o-origin: 70%; }
  76% {
    opacity: 0;
    -o-transform: rotate(900deg); }
  100% {
    opacity: 0;
    -o-transform: rotate(900deg); } }

@-ms-keyframes loading-orbit {
  0% {
    opacity: 1;
    z-index: 99;
    -ms-transform: rotate(180deg);
    -ms-animation-timing-function: ease-out; }
  7% {
    opacity: 1;
    -ms-transform: rotate(300deg);
    -ms-animation-timing-function: linear;
    -ms-origin: 0%; }
  30% {
    opacity: 1;
    -ms-transform: rotate(410deg);
    -ms-animation-timing-function: ease-in-out;
    -ms-origin: 7%; }
  39% {
    opacity: 1;
    -ms-transform: rotate(645deg);
    -ms-animation-timing-function: linear;
    -ms-origin: 30%; }
  70% {
    opacity: 1;
    -ms-transform: rotate(770deg);
    -ms-animation-timing-function: ease-out;
    -ms-origin: 39%; }
  75% {
    opacity: 1;
    -ms-transform: rotate(900deg);
    -ms-animation-timing-function: ease-out;
    -ms-origin: 70%; }
  76% {
    opacity: 0;
    -ms-transform: rotate(900deg); }
  100% {
    opacity: 0;
    -ms-transform: rotate(900deg); } }

@-webkit-keyframes loading-orbit {
  0% {
    opacity: 1;
    z-index: 99;
    -webkit-transform: rotate(180deg);
    -webkit-animation-timing-function: ease-out; }
  7% {
    opacity: 1;
    -webkit-transform: rotate(300deg);
    -webkit-animation-timing-function: linear;
    -webkit-origin: 0%; }
  30% {
    opacity: 1;
    -webkit-transform: rotate(410deg);
    -webkit-animation-timing-function: ease-in-out;
    -webkit-origin: 7%; }
  39% {
    opacity: 1;
    -webkit-transform: rotate(645deg);
    -webkit-animation-timing-function: linear;
    -webkit-origin: 30%; }
  70% {
    opacity: 1;
    -webkit-transform: rotate(770deg);
    -webkit-animation-timing-function: ease-out;
    -webkit-origin: 39%; }
  75% {
    opacity: 1;
    -webkit-transform: rotate(900deg);
    -webkit-animation-timing-function: ease-out;
    -webkit-origin: 70%; }
  76% {
    opacity: 0;
    -webkit-transform: rotate(900deg); }
  100% {
    opacity: 0;
    -webkit-transform: rotate(900deg); } }

@-moz-keyframes loading-orbit {
  0% {
    opacity: 1;
    z-index: 99;
    -moz-transform: rotate(180deg);
    -moz-animation-timing-function: ease-out; }
  7% {
    opacity: 1;
    -moz-transform: rotate(300deg);
    -moz-animation-timing-function: linear;
    -moz-origin: 0%; }
  30% {
    opacity: 1;
    -moz-transform: rotate(410deg);
    -moz-animation-timing-function: ease-in-out;
    -moz-origin: 7%; }
  39% {
    opacity: 1;
    -moz-transform: rotate(645deg);
    -moz-animation-timing-function: linear;
    -moz-origin: 30%; }
  70% {
    opacity: 1;
    -moz-transform: rotate(770deg);
    -moz-animation-timing-function: ease-out;
    -moz-origin: 39%; }
  75% {
    opacity: 1;
    -moz-transform: rotate(900deg);
    -moz-animation-timing-function: ease-out;
    -moz-origin: 70%; }
  76% {
    opacity: 0;
    -moz-transform: rotate(900deg); }
  100% {
    opacity: 0;
    -moz-transform: rotate(900deg); } }

#sidenav {
  border-bottom: 1px solid #8a9e92;
  margin-left: 0; }
  #sidenav span, #sidenav a {
    color: #000;
    display: block;
    padding: 10px 10px 10px 20px; }
  #sidenav a.active, #sidenav span.active {
    background-color: #8a9e92;
    color: #fff; }
    #sidenav a.active i, #sidenav span.active i {
      color: #fff; }
  #sidenav a.span.menu-link:hover, #sidenav span.menu-link:hover {
    cursor: pointer;
    color: #fff;
    background-color: #89959b; }
  #sidenav li {
    font-size: 16px;
    font-weight: 300;
    list-style: none; }
  #sidenav li.active, #sidenav a.active {
    background: #8a9e92; }
    #sidenav li.active span, #sidenav li.active .icon, #sidenav a.active span, #sidenav a.active .icon {
      color: #fff; }
  #sidenav li.parent-active {
    background-color: #427a7b; }
    #sidenav li.parent-active span, #sidenav li.parent-active i {
      color: #fff; }
    #sidenav li.parent-active :after {
      border-color: #fff transparent transparent; }
  #sidenav .icon {
    padding-right: 20px;
    color: #8a9e92; }
  #sidenav ul.nested {
    margin: 0;
    display: none; }
    #sidenav ul.nested span, #sidenav ul.nested a {
      padding-left: 60px; }

.context-menu {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #d4d0d0;
  top: 33px;
  left: 20px;
  min-width: 130px; }
  .context-menu ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    line-height: 1; }
  .context-menu li {
    font-size: 14px;
    color: #000;
    padding: 15px; }
  .context-menu li a {
    color: #000; }
  .context-menu li:hover,
  .context-menu li:hover i,
  .context-menu li:hover a {
    cursor: pointer;
    background-color: #8a9e92;
    color: #fff; }
  .context-menu li .icon {
    padding-right: 10px; }

.file-bar {
  background-color: #e9e9e9;
  padding: 7px 10px 7px 40px;
  margin: 0px -50px 0px -50px;
  font-size: 14px;
  color: #333; }
  .file-bar span {
    cursor: default; }
  .file-bar .back-button {
    cursor: pointer;
    padding-right: 10px; }

#files-dd {
  border: 1px solid #aeaeae;
  width: 300px;
  height: 300px;
  overflow: scroll;
  padding-bottom: 10px;
  margin: -5px 0 0 61px;
  background: #fff;
  position: absolute;
  z-index: 2;
  left: 0; }
  #files-dd .files-title {
    background-color: #eee;
    padding: 8px 0 8px 12px;
    font-weight: 900; }
  #files-dd .toggle-all {
    float: right;
    margin: 4px; }
  #files-dd ul {
    padding: 5px 10px;
    list-style: none;
    cursor: default;
    margin-left: 0;
    margin-bottom: 0; }
  #files-dd a {
    color: #3c3c3c;
    cursor: pointer; }
  #files-dd a:hover {
    text-decoration: underline; }
  #files-dd li {
    margin-left: 15px; }
  #files-dd .folder-toggle {
    width: 10px;
    display: inline-block;
    margin-left: -15px;
    cursor: pointer; }
  #files-dd .download-history.new a, #files-dd .download-history.new i {
    color: #8a9e92; }
  #files-dd .download-history.updated a, #files-dd .download-history.updated i {
    color: #5272ff; }
  #files-dd .download-history.downloaded a, #files-dd .download-history.downloaded i {
    color: #808080; }
  #files-dd .search-bar input {
    margin-bottom: 0; }
  #files-dd #indexSearchResults {
    padding: 10px; }

.filter-form .row {
  height: 45px; }

.filter-form label {
  font-weight: bold; }

#user_map {
  width: 100%;
  height: 300px; }

#map-wrapper {
  margin: 25px 0 0 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 1;
  margin-top: 0;
  /**
   * Map wrapper Overrides
   */ }
  #map-wrapper h4 {
    cursor: pointer;
    border-top: 1px solid #8a9e92;
    padding-top: 10px; }
    #map-wrapper h4:hover {
      color: #a6b5ac; }
  #map-wrapper #map-control-menu {
    background: #fff;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 350px;
    padding: 0 20px;
    overflow-y: auto; }
    #map-wrapper #map-control-menu select {
      margin-top: 15px;
      border: #8a9e92 1px solid; }
    #map-wrapper #map-control-menu #map-search input {
      margin-top: 15px; }
  #map-wrapper #map {
    position: absolute;
    width: calc(100vw - 120px);
    top: 50px;
    left: 50px;
    height: calc(100vh - 100px); }
  #map-wrapper #icons-wrapper {
    position: absolute;
    top: 60px;
    right: 80px;
    z-index: 2;
    background: #fff;
    border-radius: 3px; }
    #map-wrapper #icons-wrapper div {
      float: right;
      cursor: pointer;
      padding: 7px 9px;
      border-radius: 3px;
      height: 30px;
      width: 30px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50%; }
      #map-wrapper #icons-wrapper div i {
        color: #000; }
      #map-wrapper #icons-wrapper div:hover {
        background-color: #587c92; }
    #map-wrapper #icons-wrapper #expand-icon {
      right: 10px; }
    #map-wrapper #icons-wrapper #labels-icon {
      right: 40px; }
    #map-wrapper #icons-wrapper #pylon-icon {
      right: 70px;
      background-image: url("images/maps/pylon.svg"); }
    #map-wrapper #icons-wrapper #polygon-icon {
      right: 100px;
      background-image: url("images/maps/pencil.svg"); }
  #map-wrapper #map-context-menu {
    position: absolute;
    top: 100px;
    left: 100px;
    background: #fff;
    z-index: 1;
    border: 1px solid #888;
    border-radius: 3px;
    box-shadow: 1px 1px #aaa; }
    #map-wrapper #map-context-menu div {
      padding: 10px 30px 10px 10px; }
      #map-wrapper #map-context-menu div:hover {
        background: #8a9e92;
        color: #fff; }
  #map-wrapper #submit-button-wrapper {
    position: absolute;
    bottom: 100px;
    margin: 0 auto;
    z-index: 10;
    width: 100%; }
  #map-wrapper.map-hidden {
    left: -99999px; }
  #map-wrapper.with-panel #map {
    left: 300px;
    width: calc(100vw - 370px); }
  #map-wrapper.with-panel #submit-button {
    left: 120px; }
  #map-wrapper #map-panel {
    background: #fff;
    height: calc(100% - 100px);
    width: 250px;
    left: 50px;
    top: 50px;
    position: absolute;
    border-right: 1px solid #8a9e92;
    overflow-y: auto; }
    #map-wrapper #map-panel input, #map-wrapper #map-panel textarea, #map-wrapper #map-panel select, #map-wrapper #map-panel button, #map-wrapper #map-panel .mce-tinymce.mce-container.mce-panel {
      width: 90%;
      margin: 10px auto; }
    #map-wrapper #map-panel .site-info-panel {
      cursor: pointer;
      padding: 5px 10px; }
      #map-wrapper #map-panel .site-info-panel:hover, #map-wrapper #map-panel .site-info-panel.active {
        background: #8a9e92;
        color: #fff; }

.page-breadcrumb {
  background: #e9e9e9;
  padding: 7px 10px 7px 40px;
  margin: 0 -35px 20px -35px;
  list-style-type: none; }
  .page-breadcrumb li {
    display: inline-block; }
    .page-breadcrumb li i:hover {
      color: #8a9e92; }
  .page-breadcrumb li.link {
    color: #8a9e92;
    cursor: pointer; }
  .page-breadcrumb li.link:hover {
    text-decoration: underline; }

.site-header #files-dd {
  top: 115px; }

.site-header .dropdown-toggle {
  margin-right: 5px; }

.site,
.client-view-folders,
.client-view-documents {
  /* display: flex; */
  justify-content: space-between;
  padding: 30px 0 30px 0;
  overflow: hidden; }
  .site .row,
  .client-view-folders .row,
  .client-view-documents .row {
    background-color: #fff;
    border-top: 0 solid #fff;
    font-size: 14px;
    text-align: center;
    margin-top: 0; }
  .site .layer i,
  .site .document,
  .site .folder,
  .client-view-folders .layer i,
  .client-view-folders .document,
  .client-view-folders .folder,
  .client-view-documents .layer i,
  .client-view-documents .document,
  .client-view-documents .folder {
    width: 80px;
    height: 80px;
    background-color: #8a9e92;
    border-radius: 100%;
    color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto auto 5px;
    font-size: 38px;
    padding-top: 5px; }
    .site .layer i.special,
    .site .document.special,
    .site .folder.special,
    .client-view-folders .layer i.special,
    .client-view-folders .document.special,
    .client-view-folders .folder.special,
    .client-view-documents .layer i.special,
    .client-view-documents .document.special,
    .client-view-documents .folder.special {
      background-color: #0c2340; }
      .site .layer i.special:hover,
      .site .document.special:hover,
      .site .folder.special:hover,
      .client-view-folders .layer i.special:hover,
      .client-view-folders .document.special:hover,
      .client-view-folders .folder.special:hover,
      .client-view-documents .layer i.special:hover,
      .client-view-documents .document.special:hover,
      .client-view-documents .folder.special:hover {
        background-color: #143a6b; }
    .site .layer i:hover,
    .site .document:hover,
    .site .folder:hover,
    .client-view-folders .layer i:hover,
    .client-view-folders .document:hover,
    .client-view-folders .folder:hover,
    .client-view-documents .layer i:hover,
    .client-view-documents .document:hover,
    .client-view-documents .folder:hover {
      background-color: #a6b5ac; }
    .site .layer i.downloaded,
    .site .document.downloaded,
    .site .folder.downloaded,
    .client-view-folders .layer i.downloaded,
    .client-view-folders .document.downloaded,
    .client-view-folders .folder.downloaded,
    .client-view-documents .layer i.downloaded,
    .client-view-documents .document.downloaded,
    .client-view-documents .folder.downloaded {
      background-color: #808080; }
      .site .layer i.downloaded:hover,
      .site .document.downloaded:hover,
      .site .folder.downloaded:hover,
      .client-view-folders .layer i.downloaded:hover,
      .client-view-folders .document.downloaded:hover,
      .client-view-folders .folder.downloaded:hover,
      .client-view-documents .layer i.downloaded:hover,
      .client-view-documents .document.downloaded:hover,
      .client-view-documents .folder.downloaded:hover {
        background-color: #9a9a9a; }
  .site .single-doc,
  .client-view-folders .single-doc,
  .client-view-documents .single-doc {
    border-top: 0px solid white;
    width: 140px;
    text-align: center;
    float: left;
    position: relative; }
  .site .single-doc.item-name,
  .client-view-folders .single-doc.item-name,
  .client-view-documents .single-doc.item-name {
    font-weight: 300; }
  .site .icon-area,
  .client-view-folders .icon-area,
  .client-view-documents .icon-area {
    width: 80px;
    height: 80px;
    background-color: #8a9e92;
    border-radius: 100%;
    color: #fff;
    left: -5px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto auto 5px;
    font-size: 38px;
    padding-top: 5px;
    max-width: 100% !important; }
    .site .icon-area .fa,
    .client-view-folders .icon-area .fa,
    .client-view-documents .icon-area .fa {
      color: #fff;
      font-size: 38px;
      margin-left: -8px;
      margin-top: -5px; }
  .site .icon-area.special,
  .client-view-folders .icon-area.special,
  .client-view-documents .icon-area.special {
    background-color: #0c2340; }
  .site .section,
  .client-view-folders .section,
  .client-view-documents .section {
    width: 100% !important;
    max-width: 100% !important;
    margin: auto; }
    .site .section .item-name,
    .client-view-folders .section .item-name,
    .client-view-documents .section .item-name {
      font-size: 14px;
      color: #333;
      font-weight: 400; }

.access-time {
  padding-right: 15px; }

#colorSelector {
  border-radius: 100%;
  background: #8a9e92;
  height: 150px;
  width: 150px;
  margin: 0 auto; }

.site-content.admin .category,
.site-content.admin .document {
  font-size: 18px;
  border-top: 1px solid #8a9e92;
  margin: auto; }
  .site-content.admin .category .row-nav,
  .site-content.admin .document .row-nav {
    padding-bottom: 10px;
    margin: auto; }
  .site-content.admin .category .icon-area,
  .site-content.admin .document .icon-area {
    padding: 12px 0 0 10px; }
    .site-content.admin .category .icon-area .fa,
    .site-content.admin .document .icon-area .fa {
      font-size: 42px;
      color: #8a9e92; }
  .site-content.admin .category .section,
  .site-content.admin .document .section {
    padding: 15px 0 10px 0;
    position: relative; }

.category-head {
  padding-bottom: 15px; }
  .category-head h1 {
    font-size: 26px;
    font-weight: 700; }
  .category-head button {
    width: 125px; }

.new-folders .category {
  margin-bottom: 0 !important; }

#addNewFolder {
  width: 100% !important; }

#newfolder-wrap {
  background-color: #8a9e92;
  color: #fff; }
  #newfolder-wrap .category .icon-area .fa {
    color: #fff; }
    #newfolder-wrap .category .icon-area .fa input {
      padding-left: 12px; }

.category-icons {
  background: #8a9e92;
  border-radius: 100%;
  margin: 10px; }

.category-icons:hover {
  background-color: #a6b5ac; }

.category-icons.selected {
  background-color: #6f8678; }

.file-border {
  border: 1px solid #8a9e92;
  min-height: 85%;
  padding-top: 40px;
  position: relative;
  background: no-repeat center;
  background-clip: padding-box; }
  .file-border p {
    font-size: 14px;
    color: #626262;
    text-align: center;
    margin-bottom: 0; }

.file-border.extra-padding {
  min-height: 142px; }

.file-uploads {
  position: absolute;
  top: 0;
  overflow: hidden;
  height: 126px; }
  .file-uploads input[type="file"] {
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0); }

.edit-site .mce-panel {
  margin-top: 5px; }

.site-row-wrapper {
  padding-top: 15px;
  margin-bottom: 15px; }
  .site-row-wrapper.has-access, .site-row-wrapper.has-access label {
    background-color: #587c92;
    color: #fff; }
  .site-row-wrapper .site-row-name {
    font-size: 18px; }

.district-select {
  margin-top: 15px; }

.users-attr {
  position: relative; }

.users-attr .context-menu {
  top: 29px !important;
  left: 15px !important; }

.activity-log-header {
  margin-top: 7px;
  font-size: 24px;
  font-weight: 900; }
