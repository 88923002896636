.interactive:hover {
  background-color: $primary-color-3;
}

input[disabled], input[disabled]:hover{
  background-color: $disabled-color-1-bg;
  color: $disabled-color-1-fg;
  border: 1px solid $disabled-color-1-bg;
  cursor: default;
}
