.file-bar{
  background-color:#e9e9e9;
  padding:7px 10px 7px 40px;
  margin: 0px -50px 0px -50px;
  font-size:14px;
  color:#333;

  span{
    cursor: default;
  }

  .back-button{
    cursor: pointer;
    padding-right: 10px;
  }

}

#files-dd {
  border: 1px solid #aeaeae;
  width: 300px;
  height: 300px;
  overflow: scroll;
  padding-bottom: 10px;
  margin: -5px 0 0 61px;
  background: #fff;
  position: absolute;
  z-index:2;
  left: 0;

  .files-title{
    background-color: #eee;
    padding: 8px 0 8px 12px;
    font-weight: 900;
  }

  .toggle-all{
    float:right;
    margin: 4px;
  }

  ul {
    padding: 5px 10px;
    list-style: none;
    cursor: default;
    margin-left:0;
    margin-bottom: 0;
  }

  a {
    color: #3c3c3c;
    cursor: pointer;
  }

  a:hover {
    text-decoration: underline;
  }


  // The +/- toggle button to open/close folders
  li{
    margin-left:15px;
  }

  .folder-toggle {
    width: 10px;
    display: inline-block;
    margin-left: -15px;
    cursor: pointer;
  }

  // Whether or not it's been downloaded
  .download-history {
    &.new {
      a, i {
        color: $primary-color-1;
      }
    }
    &.updated {
      a, i {
        color: #5272ff;
      }
    }
    &.downloaded {
      a, i {
        color: #808080;
      }
    }
  }

  .search-bar input{
    margin-bottom: 0;
  }

  #indexSearchResults{
    padding: 10px;
  }

}