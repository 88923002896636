.filter-form{

  .row{
    height: 45px;
  }

  label{
    font-weight: bold;
  }

}