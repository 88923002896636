
/* The Modal (background) */
#modal-popup, #modal-popup-alert {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  transition: display 1s;

}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 400px; /* Could be more or less, depending on screen size */
  overflow: auto;
  border-radius: 0px;
  position: relative;
}

.modal-content p {
  font-size: 14px;
  color: #333;
}

.modal-content button {
  padding: 5px 15px 5px 15px;
  background-color: $primary-color-1;
  color: #fff;
  font-size: 14px;
  border: 0px;
  outline: 0px;
}

/* The Close Button */
.closebtn {
  color: #aaa;
  right: 6px;
  top: -7px;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
}

.closebtn:hover,
.closebtn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}