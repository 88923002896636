#sidenav {
  border-bottom: 1px solid $primary-color-1;
  margin-left: 0;

  // Link colours
  span, a{
    color: #000;
    display: block;
    padding: 10px 10px 10px 20px;
  }

  a.active, span.active{
    background-color: $primary-color-1;
    color: #fff;
    i{
      color: #fff;
    }
  }

  a.span.menu-link:hover, span.menu-link:hover{
    cursor: pointer;
    color: #fff;
    background-color: #89959b;
  }

  li{
    font-size:16px;
    font-weight:300;
    list-style: none;
  }

  // Active menu item
  li.active, a.active {
      background: $primary-color-1;
      span, .icon {
        color: #fff;
      }
    }

    // Expanded item turning grey background
  li.parent-active{
      background-color: $primary-color-2;

      span, i {
        color: #fff
      }

      :after {
        border-color: #fff transparent transparent
      }
    }

  .icon{
    padding-right:20px;
    color: $primary-color-1;
  }

  ul.nested{
    margin: 0;
    display: none;

    span, a{
      padding-left: 60px;
    }

  }

}

