.site-row-wrapper{
  padding-top: 15px;
  margin-bottom: 15px;

  &.has-access, &.has-access label{
    background-color: $primary-color-3;
    color: #fff;
  }

  .site-row-name{
    font-size: 18px;
  }
}

.district-select{
  margin-top:15px;
}

.users-attr {
  position: relative;
}

.users-attr .context-menu {
  top: 29px !important;
  left: 15px !important;
}