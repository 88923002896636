.context-menu {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #d4d0d0;
  top: 33px;
  left: 20px;
  min-width: 130px;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    line-height: 1;
  }

  li{
    font-size: 14px;
    color: #000;
    padding: 15px;
  }

  li a{
    color: #000;
  }

  li:hover,
  li:hover i,
  li:hover a{
    cursor: pointer;
    background-color: $primary-color-1;
    color: #fff;
  }

  li .icon {
    padding-right: 10px;
  }



}
