#colorSelector {
  border-radius: 100%;
  background: $primary-color-1;
  height: 150px;
  width: 150px;
  margin: 0 auto;
}

.site-content.admin {
  .category,
  .document {
    font-size: 18px;
    border-top: 1px solid $primary-color-1;
    margin: auto;

    .row-nav {
      padding-bottom: 10px;
      margin: auto;
    }

    .icon-area {
      padding: 12px 0 0 10px;

      .fa {
        font-size: 42px;
        color: $primary-color-1;
      }

    }

    .section {
      padding: 15px 0 10px 0;
      position: relative;
    }

  }
}

.category-head {
  padding-bottom: 15px;
  h1 {
    font-size: 26px;
    font-weight: 700;
  }

  button {
    width: 125px;
  }
}

.new-folders .category {
  margin-bottom: 0 !important;
}

#addNewFolder {
  width: 100% !important;
}

#newfolder-wrap {
  background-color: $primary-color-1;
  color: #fff;

  .category {

    .icon-area .fa {
      color: #fff;

      input {
        padding-left: 12px;
      }
    }
  }
}

// Categories
.category-icons {
  background: $primary-color-1;
  border-radius: 100%;
  margin: 10px;
}

.category-icons:hover {
  background-color: $hover-color-1;
}

.category-icons.selected {
  background-color: $selected-color-1;
}

.file-border {
  border: 1px solid $primary-color-1;
  min-height: 85%;
  padding-top: 40px;
  position: relative;
  background: no-repeat center;
  background-clip: padding-box;

  p {
    font-size: 14px;
    color: #626262;
    text-align: center;
    margin-bottom: 0;
  }
}

.file-border.extra-padding {
  min-height: 142px;
}

.file-uploads {
  position: absolute;
  top: 0;
  overflow: hidden;
  height: 126px;

  input[type="file"] {
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}

.edit-site {

  .mce-panel {
    margin-top: 5px;
  }

}
