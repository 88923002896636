$map-panel-width: 250px;

#user_map{
  width: 100%;
  height: 300px;
}

#map-wrapper {
  margin: 25px 0 0 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 1;
  margin-top: 0;


  h4 {
    cursor: pointer;
    &:hover {
      color: $hover-color-1;
    }
    border-top: 1px solid $primary-color-1;
    padding-top: 10px;
  }


  #map-control-menu {
    background: #fff;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 350px;
    padding: 0 20px;
    overflow-y: auto;

    select {
      margin-top: 15px;
      border: $primary-color-1 1px solid;
    }

    #map-search input {
      margin-top: 15px;
    }

  }

  #map {
    position: absolute;
    width: calc(100vw - 120px);
    top: 50px;
    left: 50px;
    height: calc(100vh - 100px);
  }

  #icons-wrapper {
    position: absolute;
    top: 60px;
    right: 80px;
    z-index: 2;
    background: #fff;
    border-radius: 3px;

    div {
      float: right;
      cursor: pointer;
      padding: 7px 9px;
      border-radius: 3px;
      height: 30px;
      width: 30px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50%;

      i {
        color: #000;
      }

      &:hover {
        background-color: $primary-color-3;
      }

    }

    #expand-icon {
      right: 10px;
    }

    #labels-icon {
      right: 40px;
    }

    #pylon-icon {
      right: 70px;
      background-image: url('images/maps/pylon.svg');

    }

    #polygon-icon {
      right: 100px;
      background-image: url('images/maps/pencil.svg');
    }

  }

  #map-context-menu {
    position: absolute;
    top: 100px;
    left: 100px;
    background: #fff;
    z-index: 1;
    border: 1px solid #888;
    border-radius: 3px;
    box-shadow: 1px 1px #aaa;

    div {
      padding: 10px 30px 10px 10px;

      &:hover {
        background: $primary-color-1;
        color: #fff;
      }
    }

  }

  #submit-button-wrapper {
    position: absolute;
    bottom: 100px;
    margin: 0 auto;
    z-index: 10;
    width: 100%;

  }


  /**
   * Map wrapper Overrides
   */

  &.map-hidden {
    left: -99999px;
  }

  // With site panel
  &.with-panel {

    #map {
      left: ($map-panel-width + 50);
      width: calc(100vw - #{$map-panel-width + 120});
    }
    #submit-button{
      left: $map-panel-width - 130px;
    }

  }

  #map-panel{
    background: #fff;
    height: calc(100% - 100px);
    width: $map-panel-width;
    left: 50px;
    top: 50px;
    position: absolute;
    border-right: 1px solid $primary-color-1;
    overflow-y: auto;

    input, textarea, select, button, .mce-tinymce.mce-container.mce-panel{
      width: 90%;
      margin: 10px auto;
    }

    .site-info-panel{
      cursor: pointer;
      padding: 5px 10px;

      &:hover, &.active{
        background: $primary-color-1;
        color: #fff;
      }

    }
  }

}