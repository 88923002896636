$primary-color-1: #8a9e92;
$primary-color-2: #427a7b;
$primary-color-3: #587c92;
$background-color: #fff;
$error-color-1: #441029;
$selected-color-1: darken($primary-color-1, 10%);
$selected-color-2: rgba($primary-color-1, .2);
$hover-color-1: lighten($primary-color-1, 10%);
$disabled-color-1-fg: #676767;
$disabled-color-1-bg: #ccc;

