.page-breadcrumb {
  background: #e9e9e9;
  padding: 7px 10px 7px 40px;
  margin: 0 -35px 20px -35px;
  list-style-type: none;

  li {
    display: inline-block;

    i:hover {
      color: $primary-color-1;
    }
  }
  li.link {
    color: $primary-color-1;
    cursor: pointer;
  }
  li.link:hover {
    text-decoration: underline;
  }

}

.site-header{
  #files-dd{
    top: 115px;
  }
  .dropdown-toggle{
    margin-right: 5px;
  }
}

.site,
.client-view-folders,
.client-view-documents {
  /* display: flex; */
  justify-content: space-between;
  padding: 30px 0 30px 0;
  overflow: hidden;

  .row {
    background-color: #fff;
    border-top: 0 solid #fff;
    font-size: 14px;
    text-align: center;
    margin-top: 0;
  }

  .layer i,
  .document,
  .folder {
    width: 80px;
    height: 80px;
    background-color: $primary-color-1;
    border-radius: 100%;
    color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto auto 5px;
    font-size: 38px;
    padding-top: 5px;

    &.special {
      background-color: #0c2340;
      &:hover{
        background-color: lighten(#0c2340, 10%);
      }

    }

    &:hover{
      background-color: $hover-color-1;
    }

    &.downloaded{
      background-color: #808080;
      &:hover{
        background-color: lighten(#808080, 10%);
      }
    }
  }


  .single-doc {
    border-top: 0px solid white;
    width: 140px;
    text-align: center;
    float: left;
    position: relative;

  }

  .single-doc.item-name {
    font-weight: 300;
  }

  .icon-area {
    width: 80px;
    height: 80px;
    background-color: $primary-color-1;
    border-radius: 100%;
    color: #fff;
    left: -5px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto auto 5px;
    font-size: 38px;
    padding-top: 5px;
    max-width: 100% !important;

    .fa {
      color: #fff;
      font-size: 38px;
      margin-left: -8px;
      margin-top: -5px;
    }

  }
  .icon-area.special {
    background-color: #0c2340;
  }

  .section {
    width: 100% !important;
    max-width: 100% !important;
    margin: auto;

    .item-name {
      font-size: 14px;
      color: #333;
      font-weight: 400;
    }
  }

}


.access-time {
  padding-right: 15px;
}
