html {
  min-height: 100vh;
  border-top: 3px solid $primary-color-1;
}

body {
  font-family: arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #333;
  background: url(http://www.influence.co.uk/wp-content/uploads/2014/06/rhf-01-1248x702.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @include transition(background, 1s);
}

body:before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

// Spacing overrides
.row.with-margins {
  margin: auto 10px;
}

// Fix for foundation on IE
.columns, .column{
  display:inline-block
}

/* ---------------------- Misc -----------------------*/
.hidden {
  display: none;
}

#response {
  /*padding: 10px 10px 10px 0px;*/
}

.spacing {
  height: 40px;
}

.fright {
  position: relative;
  float: right;
}

.fleft {
  position: relative;
  float: left;
}

.tright {
  text-align: right;
}

h1 {
  font-size: 24px;
  font-weight: 600;
}

#main-wrapper {
  min-height: 100vh;
  z-index: 2;
  position: relative;
}

#left-nav, header {
  background-color: #dcdcdc;
}

.top-message{
  background: $primary-color-1;
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  padding: 5px;
  font-size: 14px;
  top: 0;
  left: 0;
}

header {
  height: 3.5rem;
  padding: 15px 50px;
  font-size: 14px;
  font-weight: 400;
}

header .content {
  margin-top: 3px;
}

header .return {
  margin-left: -30px;
}

header .logout {
  margin-left: 30px;
  text-align: right;
}

header .spacer {
  padding: 0 7px 0 7px;
}

header a {
  color: #333;
}

main {
  padding: 40px 50px 40px 50px;
}

#logo {
  height: 12rem;
}

#logo img {
  display: block;
  margin: auto;
  padding-top: 25px;
}

/** Extra styles **/
.no-padding {
  padding: 0;
  margin: 0;
}

#pjax-container {
  position: relative;
}

.login-container {
  width: 400px;
  margin: auto;
  background-color: #fff;
  padding: 20px 70px 20px 70px;
  min-height: 99.7vh;
  z-index: 9999;
  position: relative;
}

.login-container button {
  width: 100%;
  background-color: $primary-color-1;
  text-align: center;
  color: #fff;
  padding: 15px;
  border-bottom: 5px solid $primary-color-3;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.login-container a {
  color: #333;
  text-decoration: underline;
}

.datepicker.dropdown-menu td.day:hover,
.datepicker.dropdown-menu span.hour:hover,
.datepicker.dropdown-menu span.minute:hover {
  background-color: $primary-color-1;
  color: #fff;
}

.datepicker.dropdown-menu .active,
.datepicker.dropdown-menu td.day.active,
.datepicker.dropdown-menu span.minute.active,
.datepicker.dropdown-menu span.hour.active {
  background-color: $primary-color-1;
  color: #fff;
}

/* ------------ Autocomplete -----------*/
.autocomplete-search-results {
  max-height: 200px;
  overflow: hidden;
  border: 1px solid #ccc;
}

.autocomplete-search-results.hidden {
  display: none;
}

.autocomplete-search-results .result {
  padding: 10px;
  margin-left: -40px;
}

.autocomplete-search-results .result:hover {
  background-color: #211b56;
  color: #fff;
}

/* ---------------------- Sites ---------------------- */

#right-content {
  background-color: #fff;
}

.list-sites {
  display: flex;
  justify-content: space-between;
}

.site {
  text-align: center;
  width: 120px;
  float: left;
  position: relative;
}

.site:hover {
  cursor: pointer;
}

.site p {
  font-size: 14px;
}

.site-content {
  position: relative;
  margin-top: 10px;
}

.site-btn {
  height: 100px;
  width: 100px;
  background-color: $primary-color-1;
  border-radius: 100%;
  margin: 15px auto;
  padding-top: 3px;
}

.site-btn.special {
  background-color: #0c2340;
}

.site-btn .layer {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}

.site-btn:hover .layer {
  opacity: 0.3;
  z-index: 100;
}

.site-btn:hover .layer .featured-img {
  z-index: 1;
  position: relative;
}

.site-btn .featured-img {
  border-radius: 100%;
  width: 94px;
  height: 94px;
  background-size: cover;
  background-position: center;
  margin: auto;
}

.single-doc {
  font-size: 18px;
  width: 100%;
  margin: auto;
  border-top: 1px solid $primary-color-1;
  padding-bottom: 10px;
}

.single-doc .row {
  padding-left: 15px;
  padding-right: 30px;
}

.single-doc .icon-area {
  padding: 12px 0px 0px 10px;
}

.single-doc .icon-area .fa {
  font-size: 46px;
  color: $primary-color-1;
}

.single-doc .section {
  padding: 20px 0px 10px 0px;
  position: relative;
}

.sub-categories {
  margin-left: 20px;
}

.item-name {
  position: relative;
  float: left;
  width: 90%;
  font-size: 14px;
  color: #1a1a1a;
  font-weight: 600;
}

.item-name p {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 0px;
}

.item-name p.updated {
  color: #555;
  font-weight: 500;
}

.mce-btn button {
  width: 30px !important;
  padding: 0px 2px !important;
  background-color: rgba(0, 0, 0, 0.0) !important;
}

.save-content {
  background-color: $primary-color-1;
  font-size: 16px;
  color: #fff;
  margin: auto;
  width: 150px;
  display: block;
  padding: 10px !important;
  margin-top: 15px;
}

.item-name input {
  border-top: 0px solid #fff;
  border-right: 0px solid #fff;
  border-bottom: 1px solid #d4d0d0;
  border-left: 0px solid #fff;
  margin-bottom: 0px;
  box-shadow: inset 0 1px 2px hsla(0, 0%, 4%, .0);
  padding: 6px 0px 5px 0px;
  height: auto;
  outline: none;
}

.item-name input:focus {
  box-shadow: inset 0 1px 2px hsla(0, 0%, 4%, .0);
  border-top: 0px solid #fff;
  border-right: 0px solid #fff;
  border-bottom: 1px solid #d4d0d0;
  border-left: 0px solid #fff;
}

.item-name:hover {
  cursor: pointer;
}

.item-options {
  width: 10%;
  position: relative;
  float: right;
  padding-top: 19px;
}

.item-options .controller {
  width: 25px;
  height: 25px;
  background-color: $primary-color-1;
  color: #fff;
  float: right;
  font-size: 14px;
  text-align: center;
}

.item-options .controller:hover {
  cursor: pointer;
}

.item-options .hidden-elms {

}

.item-attr {
  font-size: 12px;
  color: #0074a2;
}

.item-attr:hover {
  cursor: pointer;
}

.controller {
  width: 25px;
  height: 25px;
  background-color: $primary-color-1;
  color: #fff;
  float: right;
  font-size: 14px;
  text-align: center;
}

.controller:hover {
  cursor: pointer;
}


.dropzone {
  min-height: 500px;
  height: 100%;
  border: 2px dashed $primary-color-1;
  text-align: center;
  font-size: 38px;
  color: #999999;
  padding-top: 100px;
  position: absolute;
  top: 0px;
  left: -25px;
  right: -25px;
  display: none;
}

.dropzone span {
  display: none;
}

.confirm-category {
  width: 100%;
  text-align: right;
  padding-right: 0px;
  padding-top: 2px;
  font-size: 25px;
}

.btn {
  padding: 9px 14px 9px 14px;
  color: #fff;
  font-weight: 300;
  margin: 0px 5px 10px 0px;
}

.grey-btn {
  background-color: #e9e9e9;
  border: 2px solid #e9e9e9;
  color: #000;
}

.pink-btn {
  background-color: $primary-color-1;
  border: 1px solid $primary-color-1;
  padding: 9px 14px 9px 14px;
  color: #fff;
  font-weight: 300;
  margin: 0px 5px 10px 0px;

  &.full-width{
    width: 100%;
  }

  &:hover{
    background: $hover-color-1;
    color: #fff;
  }

}

.pink-btn .icon {
  padding-right: 7px;
}

.add-folder-btn {
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
  padding: 9px 14px 9px 14px;
  color: #333;
  font-weight: 300;
  margin: 10px 0px 10px 0px;
}

.add-folder-btn .icon {
  padding-right: 7px;
}

.pink-btn:hover,
.add-folder-btn:hover {
  cursor: pointer;
}

.instructions {
  margin-bottom: 20px;
  color: #444;
}

.instructions p {
  color: #444;
  font-size: 14px;
  margin: 0px;
}

.folder-head{
  margin-bottom: 20px;
}

.folder-head-icon{
  margin-bottom: 20px;
}


.featured-banner {
  background-size: cover;
  height: 250px;
  width: 100%;
  background-position: center;
}

input {
  border: 1px solid $primary-color-1 !important;
}

[type=file] {
  min-height: 80%;
}

.featured-image-inputs {
  height: 150px;
}


form .row {
  margin-top: 5px;
  margin-bottom: 0;
}

form .row label {
  color: #333;
  font-size: 14px;
  padding-bottom: 5px;
}


/*.nested li .active:hover{*/
/*color:#333 !important;*/
/*background-color: #d0d0d0 !important;*/
/*}*/
.nested li .active .icon {

}

.large-btn {
  font-size: 18px;
  font-weight: 400;
  padding: 10px 30px 10px 30px;
  width: 200px;
  text-align: center;
  margin: auto;
  display: block;
  top: 15px;
  position: relative;
}

.file-border {
  border: 1px solid $primary-color-1;
  min-height: 85%;
  padding-top: 40px;
  position: relative;
  background: no-repeat center;
  background-clip: padding-box;
}

.file-border p {
  font-size: 14px;
  color: #626262;
  text-align: center;
  margin-bottom: 0px;
}

.file-uploads {
  position: absolute;
  top: 0px;
  overflow: hidden;
  height: 126px;
}

.file-uploads input[type="file"] {
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.upload-btn, #add-folder-btn {
  margin-top: 18px;
}

.prev-img {
  display: none;
  position: absolute;
  max-height: 80%;
  max-width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.user-form input[type="text"],
.user-form input[type="password"],
.user-form select{
  width: 92%;
}


.category-name {
  text-transform: capitalize;
}

p.category-updated {
  font-weight: normal;
  color: #545454;
}

.cur:hover {
  cursor: pointer;
}

.interactive:hover{
  cursor: pointer;
  background-color: $hover-color-1;
}

.nav-bk {
  padding-right: 10px;
}

.client-view {
  margin-top: 1.5rem;
}

.client-view .site-name {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.client-view .client-logo {
  display: block;
  margin: auto;
}

.dash-content {
  margin-bottom: 50px;
}

.user-list {
  margin-bottom: 50px;
}

#searchBtn {
  display: none;
}

.save-site {
  margin-top: 20px;
}

.ns-box.ns-show, .ns-box.ns-visible {
  z-index: 1000;
}

#response {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #fff;
  border: 1px solid $primary-color-1;
  z-index: 10001;
  box-shadow: 0px 0px 8px $primary-color-1;
}

#response div {
  padding: 5px;
}