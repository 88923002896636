.folder-image{
  background: $primary-color-1;
  border-radius: 100%;
  margin: 10px auto 10px auto;
  width: 65%;
  color: #fff;
  font-size: 20px;
  &:hover {
    background-color: $hover-color-1;
  }
  &.selected {
    background-color: $selected-color-1;
  }
  &.special{
    background-color: #0c2340;
    &:hover {
      background-color: lighten(#0c2340, 10%);
    }
  }

  i.fa{
    font-size: 46px;
    margin-top: 16px;
  }

  img{
    margin: 5px;
  }

}

.client-view-folders{
  .folder-image{
    width: 80px;
    height: 80px;
  }
}